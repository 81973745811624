import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Head from 'components/head'
// import Box from 'components/box';

import 'style/prices.scss'
import Cta from 'components/cta/cta'

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allIn: true,
    }
  }
  render() {
    const { data } = this.props
    const fields = JSON.parse(data.buroBorkPage.field)
    const list = data.allContractType.nodes.sort((a, b) =>
      a.max_houses > b.max_houses ? 1 : -1
    )

    return (
      <Layout locale={fields.locale} className="main-container">
        <Head pageTitle={fields.title} locale={fields.locale} />
        <section className="pricing-header mt-5 mb-4 pt-2">
          <div className="container">
            <div className="row justify-content-center px-2">
              <h1 className="pricing-header-h1 pt-5">
                Kies het abonnement dat bij je past.
              </h1>
              <div className="col-12 col-md-6 py-4">
                <p className="bukazu-p">
                  Een abonnement bij BUKAZU is per jaar en kan elk jaar verlengd
                  worden.
                </p>
                <p className="bukazu-p">
                  Omdat het systeem heel eenvoudig in gebruik is, zijn er geen
                  installatiekosten. Dat doe je eenvoudig zelf. Ook voor het in
                  gebruik nemen van het systeem heb je nauwelijks begeleiding
                  nodig. Een paar uur begeleiding zit bij de prijs in, dus bel
                  gerust bij vragen.
                </p>
                <p className="bukazu-p">
                  Wil je graag dat wij het systeem voor je installeren of heb je
                  graag een uitgebreide uitleg bij het systeem, dan brengen we
                  hiervoor eerst een offerte uit op basis van je wensen.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="mb-0 pb-0 mb-lg-5 pb-lg-5 pricing-table">
          <div className="pricing-basic">
            <div className="pricing-table-header">Basis</div>
            {list.map(contractType => {
              if (contractType.name === 'Trial' || contractType.all_in) {
                return null
              }
              return PricingItem(contractType)
            })}
          </div>
          <div className="pricing-all_in">
            <div className="pricing-table-header">All in prijzen</div>
            {list.map(contractType => {
              if (contractType.name === 'Trial' || !contractType.all_in) {
                return null
              }
              return PricingItem(contractType)
            })}
          </div>
          <div className="pricing-table-note">
            Alle genoemde prijzen zijn exclusief btw.
          </div>
        </div>
        <Cta />
      </Layout>
    )
  }
}

const PricingItem = item => {
  return (
    <div
      className={`pricing-item pricing-item-${
        item.all_in ? 'all_in' : 'basic'
      }`}
      key={item.id}
    >
      {/* {item.name} */}
      <div className="item-objects">
        {item.min_houses} - {item.max_houses} accommodaties
      </div>
      <div className="item-price text-primary">
        € {item.price},- <span>/ Jaar</span>
      </div>
      {!item.all_in && (
        <div className="item-pricing-booking">+ € 1,30 per boeking</div>
      )}
      {item.all_in && (
        <div className="item-pricing-booking">inclusief boekingen</div>
      )}
    </div>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}
export const pageQuery = graphql`
  query BuroBorkPrices {
    buroBorkPage(slug: { eq: "prijzen" }) {
      id
      field
    }
    allContractType {
      nodes {
        id
        name
        min_houses
        max_houses
        all_in
        price
      }
    }
  }
`

export default Index
